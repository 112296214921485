<template>
  <vs-popup class="popup80" :title="title" :active.sync="localShow">
    <div class="vx-row">
      <div class="vx-col md:w-1/6 w-full mt-0">
        <label class="vs-input--label">{{ account.external ? 'Altera': 'Não altera'}} saldo no cx geral.</label>
        <vs-switch class="w-1/2 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="account.external">
          <span slot="on">Externa</span> <span slot="off">Interna</span>
        </vs-switch>

        <sig-switch v-model="account.cashier" label="Vinculada ao caixa" position="bottom" class="mt-2" observation="Quando ativado, qualquer movimentação nessa conta vai ser lançada no caixa geral, seja entrada ou saída. No caso de movimentação entre duas contas vinculadas no caixa, o registro não é duplicado" />

        <!-- <vs-checkbox v-model="account.external" class="ml-0 mr-1 mt-1">Conta externa. (Altera saldo no caixa geral.)</vs-checkbox> -->
      </div>
      <div class="vx-col md:w-1/6 w-full mt-0">
        <label class="vs-input--label">Tipo de conta</label>
        <v-select v-model="account.type" :reduce="option => option.value" :clearable="false"
          :options="[
                      {label: 'Simples', value: 'simple'},
                      {label: 'Bancária', value: 'bankAccount'},
                      {label: 'Cartão', value: 'cardAccount'}
          ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
          <template #no-options="{}">
            Nenhum resultado encontrado.
          </template>
        </v-select>
      </div>
      <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input :key="'0001_'" v-model="account.name" class="w-full" label="Nome da Conta" data-vv-as="Nome da Conta" data-vv-scope="account" v-validate.initial="'required'" name="name" />
          <span :key="'0001__'" class="text-danger text-sm" v-show="errors.has('account.name')">Campo Obrigatório.</span>
      </div>
      <div class="vx-col md:w-1/3 w-full mt-0">
        <label class="vs-input--label">Tipos de pagamento da conta</label>
        <v-select v-model="account.type_payment" :reduce="option => option.value" multiple :clearable="false"
          data-vv-as="Tipo de Pagamento" data-vv-scope="account" v-validate.initial="'required'" name="type_payment"
          :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
          <template #no-options="{}">
            Nenhum resultado encontrado.
          </template>
        </v-select>
        <span class="text-danger text-sm" v-show="errors.has('account.type_payment')">Este Campo é Obrigatório.</span>
      </div>

      <div class="vx-col md:w-1/6 w-full mt-0">
        <vs-input class="w-full" label="Saldo inicial" ref="balance" v-model="account.balance" v-currency="currencyConfig" />
      </div>
    </div>

    <!-- CONTA BANCÁRIA -->
    <div v-if="account.type === 'bankAccount' || account.type === 'cardAccount'">
      <vs-divider position="left"><feather-icon icon="CreditCardIcon" class="mr-2" svgClasses="w-4 h-4" />Dados Bancários</vs-divider>
      <div class="vx-row">
        <div :class="['vx-col', account.type === 'bankAccount' ? 'md:w-4/5' : 'md:w-full', 'w-full', 'mt-0']">
          <label class="vs-input--label">Banco {{ account.type === 'bankAccount' && account.bankAccount.bank ? (account.bankAccount.bank.active ? ' | Este banco permite emissão de boletos!' : ' | Este banco NÃO permite emissão de boletos!') : '' }}</label>
          <v-select class="vue_select_drop_size_150" :key="'0002_'" @input="account.bankAccount.boleto = false"
            v-model="account.bankAccount.bank"
            data-vv-as="Banco" data-vv-scope="bankAccount" v-validate.initial="'required'" name="bank"
            :options="banks" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span :key="'0002__'" class="text-danger text-sm" v-show="errors.has('bankAccount.bank')">{{ errors.first('bankAccount.bank') }}</span>
        </div>
        <div v-if="account.type === 'bankAccount'" class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Emitir Boleto</label>
          <v-select :disabled="!(account.bankAccount.bank && account.bankAccount.bank.active)" v-model="account.bankAccount.boleto" :reduce="option => option.value" :clearable="false" :options="[{label: 'SIM', value: true}, {label: 'NÃO', value: false}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input :key="'0003_'" v-model="account.bankAccount.bankBranche.code" class="w-full" label="Agência" data-vv-as="Agência" data-vv-scope="bankAccount" v-validate.initial="'required'" name="bankBranche" />
          <span :key="'0003__'" class="text-danger text-sm" v-show="errors.has('bankAccount.bankBranche')">{{ errors.first('bankAccount.bankBranche') }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input v-model="account.bankAccount.bankBranche.dv" class="w-full" label="DV Agência" />
        </div>
        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input :key="'0004_'" v-model="account.bankAccount.currentAccount.code" class="w-full" label="Conta corrente" data-vv-as="Conta corrente" data-vv-scope="bankAccount" v-validate.initial="'required'" name="currentAccount" />
          <span :key="'0004__'" class="text-danger text-sm" v-show="errors.has('bankAccount.currentAccount')">{{ errors.first('bankAccount.currentAccount') }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input v-model="account.bankAccount.currentAccount.dv" class="w-full" label="DV Conta corrente" />
        </div>
      </div>
    </div>

    <!-- JUROS PARA CONTAS SEM BOLETO -->
    <div class="vx-row" v-if="!account.bankAccount.boleto && account.type === 'bankAccount'">
      <div class="vx-col md:w-1/4 w-full mt-2">
        <label class="vs-input--label">Juros {{ account.default_interest_type }}</label>
        <vx-input-group prependClasses="border border-solid border-grey border-r-0">
          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button @click="toggleInterestTypeAccount">{{ account.default_interest_type }}</vs-button>
            </div>
          </template>
          <vs-input key="i98784811900" v-if="account.default_interest_type === '%'" class="w-full" type="number" v-model="account.default_interest" />
          <vs-input key="di00911848789" v-else class="w-full" ref="default_interest_account" v-model="account.default_interest" v-currency="currencyConfig" />
        </vx-input-group>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-2">
        <label class="vs-input--label">Aplicar juros em</label>
        <vx-input-group>
          <vs-input type="number" v-model="account.default_interest_days" class="w-full" />
          <template slot="append"> <div class="append-text bg-primary"> <span>Dias</span> </div> </template>
        </vx-input-group>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-2">
        <label class="vs-input--label">Multa {{ account.default_fee_type }}</label>
        <vx-input-group prependClasses="border border-solid border-grey border-r-0">
          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button @click="toggleFeeTypeAccount">{{ account.default_fee_type }}</vs-button>
            </div>
          </template>
          <vs-input key="%_fe" v-if="account.default_fee_type === '%'" class="w-full" type="number" v-model="account.default_fee" />
          <vs-input key="$_fe" v-else class="w-full" ref="default_fee_account" v-model="account.default_fee" v-currency="currencyConfig" />
        </vx-input-group>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-2">
        <label class="vs-input--label">Aplicar multa em</label>
        <vx-input-group>
          <vs-input type="number" v-model="account.default_fee_days" class="w-full" />
          <template slot="append"> <div class="append-text bg-primary"> <span>Dias</span> </div> </template>
        </vx-input-group>
      </div>
    </div>

    <!-- DADOS PARA CONTA CARTÃO -->
    <div class="vx-row" v-if="account.type === 'cardAccount'">
      <div class="vx-col md:w-1/4 w-full mt-2">
        <label class="vs-input--label">Taxa Crédito {{ account.cardAccount.default_credit_tax_type }}</label>
        <vx-input-group prependClasses="border border-solid border-grey border-r-0">
          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button @click="toggleCardAccountCreditTaxType">{{ account.cardAccount.default_credit_tax_type }}</vs-button>
            </div>
          </template>
          <vs-input key="%_di" v-if="account.cardAccount.default_credit_tax_type === '%'" class="w-full" type="number" v-model="account.cardAccount.default_credit_tax" />
          <vs-input key="$_di" v-else class="w-full" ref="default_credit_tax" v-model="account.cardAccount.default_credit_tax" v-currency="currencyConfig" />
        </vx-input-group>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-2">
        <label class="vs-input--label">Dia Pag. Crédito</label>
        <vx-input-group prependClasses="border border-solid border-grey border-r-0">
          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button @click="toggleCardAccountTypeDayReceiptCredit">{{ account.cardAccount.type_day_receipt_credit === 'FIXED' ? 'Fixo' : 'Após Receb.' }}</vs-button>
            </div>
          </template>

          <v-select key="%_tdc" v-if="account.cardAccount.type_day_receipt_credit === 'FIXED'" v-model="account.cardAccount.day_receipt_credit" :reduce="option => option.value" :clearable="false"
            :options="[
              {label: '01', value: 1},
              {label: '02', value: 2},
              {label: '03', value: 3},
              {label: '04', value: 4},
              {label: '05', value: 5},
              {label: '06', value: 6},
              {label: '07', value: 7},
              {label: '08', value: 8},
              {label: '09', value: 9},
              {label: '10', value: 10},
              {label: '11', value: 11},
              {label: '12', value: 12},
              {label: '13', value: 13},
              {label: '14', value: 14},
              {label: '15', value: 15},
              {label: '16', value: 16},
              {label: '17', value: 17},
              {label: '18', value: 18},
              {label: '19', value: 19},
              {label: '20', value: 20},
              {label: '21', value: 21},
              {label: '22', value: 22},
              {label: '23', value: 23},
              {label: '24', value: 24},
              {label: '25', value: 25},
              {label: '26', value: 26},
              {label: '27', value: 27},
              {label: '28', value: 28},
            ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>

          <vs-input v-else type="number" key="$_tdc" class="w-full" v-model="account.cardAccount.day_receipt_credit" />
        </vx-input-group>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-2">
        <label class="vs-input--label">Taxa Débito {{ account.cardAccount.default_debit_tax_type }}</label>
        <vx-input-group prependClasses="border border-solid border-grey border-r-0">
          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button @click="toggleCardAccountDebitTaxType">{{ account.cardAccount.default_debit_tax_type }}</vs-button>
            </div>
          </template>
          <vs-input key="%_di" v-if="account.cardAccount.default_debit_tax_type === '%'" class="w-full" type="number" v-model="account.cardAccount.default_debit_tax" />
          <vs-input key="$_di" v-else class="w-full" ref="default_debit_tax" v-model="account.cardAccount.default_debit_tax" v-currency="currencyConfig" />
        </vx-input-group>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-2">
        <label class="vs-input--label">Dia Pag. Débito</label>
        <vx-input-group prependClasses="border border-solid border-grey border-r-0">
          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button @click="toggleCardAccountTypeDayReceiptDebit">{{ account.cardAccount.type_day_receipt_debit === 'FIXED' ? 'Fixo' : 'Após Receb.' }}</vs-button>
            </div>
          </template>

          <v-select key="%_tdc" v-if="account.cardAccount.type_day_receipt_debit === 'FIXED'" v-model="account.cardAccount.day_receipt_debit" :reduce="option => option.value" :clearable="false"
            :options="[
              {label: '01', value: 1},
              {label: '02', value: 2},
              {label: '03', value: 3},
              {label: '04', value: 4},
              {label: '05', value: 5},
              {label: '06', value: 6},
              {label: '07', value: 7},
              {label: '08', value: 8},
              {label: '09', value: 9},
              {label: '10', value: 10},
              {label: '11', value: 11},
              {label: '12', value: 12},
              {label: '13', value: 13},
              {label: '14', value: 14},
              {label: '15', value: 15},
              {label: '16', value: 16},
              {label: '17', value: 17},
              {label: '18', value: 18},
              {label: '19', value: 19},
              {label: '20', value: 20},
              {label: '21', value: 21},
              {label: '22', value: 22},
              {label: '23', value: 23},
              {label: '24', value: 24},
              {label: '25', value: 25},
              {label: '26', value: 26},
              {label: '27', value: 27},
              {label: '28', value: 28},
            ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>

          <vs-input v-else type="number" key="$_tdc" class="w-full" v-model="account.cardAccount.day_receipt_debit" />
        </vx-input-group>
      </div>
    </div>

    <!-- BOLETO -->
    <div v-if="account.bankAccount.boleto &&  account.type === 'bankAccount'">
      <vs-divider position="left"><feather-icon icon="MenuIcon" class="mr-2" svgClasses="w-4 h-4" />Configuração de Boletos</vs-divider>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input :key="'0005_'" v-model="account.bankAccount.configBoleto.especie_documento" class="w-full" label="Espécie de documento" data-vv-as="Espécie de documento" data-vv-scope="configBoleto" v-validate.initial="'required'" name="especie_documento" />
          <span :key="'0005__'" class="text-danger text-sm" v-show="errors.has('configBoleto.especie_documento')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input :key="'0006_'" v-model="account.bankAccount.configBoleto.convenio" class="w-full" label="Convênio" data-vv-as="Convênio" data-vv-scope="configBoleto" v-validate.initial="'required'" name="convenio" />
          <span :key="'0006__'" class="text-danger text-sm" v-show="errors.has('configBoleto.convenio')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input :key="'0007_'" v-model="account.bankAccount.configBoleto.carteira" class="w-full" label="Carteira" data-vv-as="Carteira" data-vv-scope="configBoleto" v-validate.initial="'required'" name="carteira" />
          <span :key="'0007__'" class="text-danger text-sm" v-show="errors.has('configBoleto.carteira')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input v-model="account.bankAccount.configBoleto.variacao_carteira" class="w-full" label="Variação Carteira" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
        <label class="vs-input--label">Impressão</label>
        <v-select v-model="account.bankAccount.configBoleto.printing_type" :reduce="option => option.value" :clearable="false" :options="[{label: 'Normal', value: 'normal'}, {label: 'Carnê', value: 'carne'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input v-model="account.bankAccount.configBoleto.next_number" class="w-full" label="Nosso nº inicial" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input v-model="account.bankAccount.configBoleto.remessa_next_number" class="w-full" label="Nº inicial remessa" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input v-model="account.bankAccount.configBoleto.posto" class="w-full" label="Posto" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">CNAB</label>
          <v-select v-model="account.bankAccount.configBoleto.cnab" :reduce="option => option.value" :clearable="false" :options="[{label: '240', value: 240}, {label: '400', value: 400}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input v-model="account.bankAccount.configBoleto.byte" class="w-full" label="Byte" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mt-2">
          <label class="vs-input--label">Juros {{ account.bankAccount.configBoleto.default_interest_type }}</label>
          <vx-input-group prependClasses="border border-solid border-grey border-r-0">
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button @click="toggleInterestType">{{ account.bankAccount.configBoleto.default_interest_type }}</vs-button>
              </div>
            </template>
            <vs-input key="%_dit" v-if="account.bankAccount.configBoleto.default_interest_type === '%'" class="w-full" type="number" v-model="account.bankAccount.configBoleto.default_interest" />
            <vs-input key="$_dit" v-else class="w-full" ref="default_interest" v-model="account.bankAccount.configBoleto.default_interest" v-currency="currencyConfig" />
          </vx-input-group>
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <label class="vs-input--label">Aplicar juros em</label>
          <vx-input-group>
            <vs-input type="number" v-model="account.bankAccount.configBoleto.default_interest_days" class="w-full" />
            <template slot="append"> <div class="append-text bg-primary"> <span>Dias</span> </div> </template>
          </vx-input-group>
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <label class="vs-input--label">Multa {{ account.bankAccount.configBoleto.default_fee_type }}</label>
          <vx-input-group prependClasses="border border-solid border-grey border-r-0">
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button @click="toggleFeeType">{{ account.bankAccount.configBoleto.default_fee_type }}</vs-button>
              </div>
            </template>
            <vs-input key="%_fee" v-if="account.bankAccount.configBoleto.default_fee_type === '%'" class="w-full" type="number" v-model="account.bankAccount.configBoleto.default_fee" />
            <vs-input key="$_fee" v-else class="w-full" ref="default_fee" v-model="account.bankAccount.configBoleto.default_fee" v-currency="currencyConfig" />
          </vx-input-group>
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <label class="vs-input--label">Aplicar multa em</label>
          <vx-input-group>
            <vs-input type="number" v-model="account.bankAccount.configBoleto.default_fee_days" class="w-full" />
            <template slot="append"> <div class="append-text bg-primary"> <span>Dias</span> </div> </template>
          </vx-input-group>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-2">
          <vs-input v-model="account.bankAccount.configBoleto.payment_place" class="w-full" label="Local de Pagamento" />
        </div>
        <div class="vx-col md:w-1/4 w-full mt-2">
          <label class="vs-input--label">Taxa Bol.</label>
          <vs-input class="w-full" ref="tax_boleto" v-model="account.bankAccount.configBoleto.tax" v-currency="currencyConfig" />
        </div>
        <div class="vx-col md:w-1/4 w-full mt-2">
          <label class="vs-input--label">Aplicar Taxa</label>
          <v-select v-model="account.bankAccount.configBoleto.tax_apply" :reduce="option => option.value" :clearable="false" :options="[{label: 'Na baixa', value: 'on_payment'}, {label: 'No Lançamento', value: 'on_register'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mt-2" v-for="(demDesc, i) in account.bankAccount.configBoleto.demonstrative_descriptions" :key="`${i}_demonstrative_description`">
          <vs-input v-model="demDesc.demonstrative_description" class="w-full" :label="`Descrição do demonstrativo linha ${i+1}`" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mt-2" v-for="(instruction, i) in account.bankAccount.configBoleto.instructions" :key="`${i}_instruction`">
          <vs-input v-model="instruction.instruction" class="w-full" :label="`Instruções linha ${i+1}`" />
        </div>
      </div>
    </div>

    <vs-divider></vs-divider>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button color="secondary" class="ml-auto" @click="cancel">{{ cancelButtonText }}</vs-button>
          <vs-button class="ml-4" @click="updateAccount" :disabled="!validateAccountForm || disableAccountSave">{{ actionButtonText }}</vs-button>
        </div>
      </div>
    </div>
  </vs-popup>
</template>

<script>
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import { setValue, getValue, CurrencyDirective } from 'vue-currency-input'

export default {
  name: 'edit-account-model',

  components: { vSelect },

  props: {
    account: {
      Object
    },
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Salvar Conta'
    },
    actionButtonText: {
      String, default: 'Salvar Conta'
    },
    cancelButtonText: {
      String, default: 'Cancelar'
    }
  },
  computed: {
    validateAccountForm () {
      if (this.account.type === 'simple' || this.account.type === 'cardAccount') {
        return !this.errors.any('account')
      } else if (this.account.type === 'bankAccount') {
        if (this.account.bankAccount.boleto) {
          return !this.errors.any()
        }
        return !this.errors.any('account') && !this.errors.any('bankAccount')
      }
    },
    banks () {
      return this.$store.getters['bank/forSelect']
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    }
  },
  data () {
    return {
      localShow: false,
      disableAccountSave: false,

      currencyConfig:  {
        currency: {prefix: 'R$ '},
        valueAsInteger: false,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false
      }
    }
  },

  directives: { currency: CurrencyDirective },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.$emit('cancel')
      }

      this.$validator.pause()
      //COMO OS CAMPOS APARECEM E SOMEM DINAMICAMENTE, PRECISO DO BLOCO ABAIXO PARA EVITAR ERROS DE VALIDAÇÃO
      this.$nextTick(() => {
        this.$validator.resume()
        this.$validator.errors.clear()
        this.$validator.validateScopes()
        if (this.$refs.default_interest) {
          setValue(this.$refs.default_interest, this.account.bankAccount.configBoleto.default_interest.toString())
        }
        if (this.$refs.default_fee) {
          setValue(this.$refs.default_fee, this.account.bankAccount.configBoleto.default_fee.toString())
        }
        if (this.$refs.default_interest_account) {
          setValue(this.$refs.default_interest_account, this.account.default_interest.toString())
        }
        if (this.$refs.default_fee_account) {
          setValue(this.$refs.default_fee_account, this.account.default_fee.toString())
        }
        if (this.$refs.balance) {
          setValue(this.$refs.balance, this.account.balance.toString())
        }
        if (this.$refs.tax_boleto) {
          setValue(this.$refs.tax_boleto, this.account.bankAccount.configBoleto.tax.toString())
        }
        if (this.$refs.default_credit_tax) {
          setValue(this.$refs.default_credit_tax, this.account.cardAccount.default_credit_tax.toString())
        }
        if (this.$refs.default_debit_tax) {
          setValue(this.$refs.default_debit_tax, this.account.cardAccount.default_debit_tax.toString())
        }
      })
      this.account.bankAccount.bank = this.banks.find(o => o.value === this.account.bankAccount.bank)
    }
  },

  methods: {
    updateAccount () {
      // Loading
      this.$vs.loading()
      this.disableAccountSave = true
      setTimeout(() => { this.disableAccountSave = false }, 2000) //TRAVA O BOTÃO SALVAR POR 2 SEGUNDOS

      const account = JSON.parse(JSON.stringify(this.account))
      account.balance = getValue(this.$refs.balance)
      if (this.$refs.tax_boleto) {
        account.bankAccount.configBoleto.tax = getValue(this.$refs.tax_boleto)
      }
      if (this.$refs.default_credit_tax) {
        account.cardAccount.default_credit_tax = getValue(this.$refs.default_credit_tax)
      }
      if (this.$refs.default_debit_tax) {
        account.cardAccount.default_debit_tax = getValue(this.$refs.default_debit_tax)
      }

      if (this.account.bankAccount.configBoleto.default_interest_type === '$') {
        account.bankAccount.configBoleto.default_interest = getValue(this.$refs.default_interest)
      }
      if (this.account.bankAccount.configBoleto.default_fee_type === '$') {
        account.bankAccount.configBoleto.default_fee = getValue(this.$refs.default_fee)
      }
      account.bankAccount.configBoleto.demonstrative_descriptions = account.bankAccount.configBoleto.demonstrative_descriptions.reduce((a, o) => (a.push(o.demonstrative_description), a), [])
      account.bankAccount.configBoleto.instructions = account.bankAccount.configBoleto.instructions.reduce((a, o) => (a.push(o.instruction), a), [])

      //SÓ PARA DEIXAR OS DADOS DA CONTA COMO PADRÃO AO UTILIZAR BOLETO
      if (account.bankAccount.boleto) {
        this.account.default_interest_type = '%'
        this.account.default_interest = 0
        this.account.default_interest_days = 1
        this.account.default_fee_type = '%'
        this.account.default_fee = 0
        this.account.default_fee_days = 1
      } else {
        if (this.account.default_interest_type === '$') {
          account.default_interest = getValue(this.$refs.default_interest_account)
        }
        if (this.account.default_fee_type === '$') {
          account.default_fee = getValue(this.$refs.default_fee_account)
        }
      }

      this.$store.dispatch('account/update', account)
        .then(() => {
          this.$vs.loading.close()
          this.$emit('saved') //refresh grid no parent
          this.localShow = false
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    cancel () {
      this.$emit('cancel')
    },
    toggleInterestType () {
      this.account.bankAccount.configBoleto.default_interest = 0
      this.account.bankAccount.configBoleto.default_interest_type = this.account.bankAccount.configBoleto.default_interest_type === '%' ? '$' : '%'
    },
    toggleFeeType () {
      this.account.bankAccount.configBoleto.default_fee = 0
      this.account.bankAccount.configBoleto.default_fee_type = this.account.bankAccount.configBoleto.default_fee_type === '%' ? '$' : '%'
    },
    toggleInterestTypeAccount () {
      this.account.default_interest = 0
      this.account.default_interest_type = this.account.default_interest_type === '%' ? '$' : '%'
    },
    toggleFeeTypeAccount () {
      this.account.default_fee = 0
      this.account.default_fee_type = this.account.default_fee_type === '%' ? '$' : '%'
    },
    toggleCardAccountCreditTaxType () {
      this.account.cardAccount.default_credit_tax = 0
      this.account.cardAccount.default_credit_tax_type = this.account.cardAccount.default_credit_tax_type === '%' ? '$' : '%'
    },
    toggleCardAccountDebitTaxType () {
      this.account.cardAccount.default_debit_tax = 0
      this.account.cardAccount.default_debit_tax_type = this.account.cardAccount.default_debit_tax_type === '%' ? '$' : '%'
    },
    toggleCardAccountTypeDayReceiptCredit () {
      this.account.cardAccount.type_day_receipt_credit = this.account.cardAccount.type_day_receipt_credit === 'FIXED' ? 'DINAMIC' : 'FIXED'
    },
    toggleCardAccountTypeDayReceiptDebit () {
      this.account.cardAccount.type_day_receipt_debit = this.account.cardAccount.type_day_receipt_debit === 'FIXED' ? 'DINAMIC' : 'FIXED'
    }
  },

  created () {
    this.$store.dispatch('bank/fetchAll').catch(err => { console.error(err) })
    this.$store.dispatch('typePayment/fetchAll').then(() => { }).catch(err => { console.error(err) })
  }
}
</script>
<style lang="scss">
  .popup80 .vs-popup {
    width: 80% !important;
  }
  .vue_select_drop_size_150 .vs__dropdown-menu {
    max-height: 150px;
  }
</style>
