var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      staticClass: "popup80",
      attrs: { title: _vm.title, active: _vm.localShow },
      on: {
        "update:active": function ($event) {
          _vm.localShow = $event
        },
      },
    },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/6 w-full mt-0" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(
                _vm._s(_vm.account.external ? "Altera" : "Não altera") +
                  " saldo no cx geral."
              ),
            ]),
            _c(
              "vs-switch",
              {
                staticClass: "w-1/2 mt-2",
                attrs: {
                  "icon-pack": "feather",
                  "vs-icon-on": "icon-check-circle",
                  "vs-icon-off": "icon-slash",
                },
                model: {
                  value: _vm.account.external,
                  callback: function ($$v) {
                    _vm.$set(_vm.account, "external", $$v)
                  },
                  expression: "account.external",
                },
              },
              [
                _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                  _vm._v("Externa"),
                ]),
                _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                  _vm._v("Interna"),
                ]),
              ]
            ),
            _c("sig-switch", {
              staticClass: "mt-2",
              attrs: {
                label: "Vinculada ao caixa",
                position: "bottom",
                observation:
                  "Quando ativado, qualquer movimentação nessa conta vai ser lançada no caixa geral, seja entrada ou saída. No caso de movimentação entre duas contas vinculadas no caixa, o registro não é duplicado",
              },
              model: {
                value: _vm.account.cashier,
                callback: function ($$v) {
                  _vm.$set(_vm.account, "cashier", $$v)
                },
                expression: "account.cashier",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/6 w-full mt-0" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Tipo de conta"),
            ]),
            _c("v-select", {
              attrs: {
                reduce: (option) => option.value,
                clearable: false,
                options: [
                  { label: "Simples", value: "simple" },
                  { label: "Bancária", value: "bankAccount" },
                  { label: "Cartão", value: "cardAccount" },
                ],
                placeholder: "Selecione",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.account.type,
                callback: function ($$v) {
                  _vm.$set(_vm.account, "type", $$v)
                },
                expression: "account.type",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/6 w-full mt-0" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              key: "0001_",
              staticClass: "w-full",
              attrs: {
                label: "Nome da Conta",
                "data-vv-as": "Nome da Conta",
                "data-vv-scope": "account",
                name: "name",
              },
              model: {
                value: _vm.account.name,
                callback: function ($$v) {
                  _vm.$set(_vm.account, "name", $$v)
                },
                expression: "account.name",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("account.name"),
                    expression: "errors.has('account.name')",
                  },
                ],
                key: "0001__",
                staticClass: "text-danger text-sm",
              },
              [_vm._v("Campo Obrigatório.")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/3 w-full mt-0" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Tipos de pagamento da conta"),
            ]),
            _c("v-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              attrs: {
                reduce: (option) => option.value,
                multiple: "",
                clearable: false,
                "data-vv-as": "Tipo de Pagamento",
                "data-vv-scope": "account",
                name: "type_payment",
                options: _vm.typePayments,
                placeholder: "Selecione",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.account.type_payment,
                callback: function ($$v) {
                  _vm.$set(_vm.account, "type_payment", $$v)
                },
                expression: "account.type_payment",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("account.type_payment"),
                    expression: "errors.has('account.type_payment')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v("Este Campo é Obrigatório.")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/6 w-full mt-0" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "currency",
                  rawName: "v-currency",
                  value: _vm.currencyConfig,
                  expression: "currencyConfig",
                },
              ],
              ref: "balance",
              staticClass: "w-full",
              attrs: { label: "Saldo inicial" },
              model: {
                value: _vm.account.balance,
                callback: function ($$v) {
                  _vm.$set(_vm.account, "balance", $$v)
                },
                expression: "account.balance",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.account.type === "bankAccount" || _vm.account.type === "cardAccount"
        ? _c(
            "div",
            [
              _c(
                "vs-divider",
                { attrs: { position: "left" } },
                [
                  _c("feather-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "CreditCardIcon", svgClasses: "w-4 h-4" },
                  }),
                  _vm._v("Dados Bancários"),
                ],
                1
              ),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    class: [
                      "vx-col",
                      _vm.account.type === "bankAccount"
                        ? "md:w-4/5"
                        : "md:w-full",
                      "w-full",
                      "mt-0",
                    ],
                  },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        "Banco " +
                          _vm._s(
                            _vm.account.type === "bankAccount" &&
                              _vm.account.bankAccount.bank
                              ? _vm.account.bankAccount.bank.active
                                ? " | Este banco permite emissão de boletos!"
                                : " | Este banco NÃO permite emissão de boletos!"
                              : ""
                          )
                      ),
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      key: "0002_",
                      staticClass: "vue_select_drop_size_150",
                      attrs: {
                        "data-vv-as": "Banco",
                        "data-vv-scope": "bankAccount",
                        name: "bank",
                        options: _vm.banks,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: {
                        input: function ($event) {
                          _vm.account.bankAccount.boleto = false
                        },
                      },
                      model: {
                        value: _vm.account.bankAccount.bank,
                        callback: function ($$v) {
                          _vm.$set(_vm.account.bankAccount, "bank", $$v)
                        },
                        expression: "account.bankAccount.bank",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("bankAccount.bank"),
                            expression: "errors.has('bankAccount.bank')",
                          },
                        ],
                        key: "0002__",
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("bankAccount.bank")))]
                    ),
                  ],
                  1
                ),
                _vm.account.type === "bankAccount"
                  ? _c(
                      "div",
                      { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v("Emitir Boleto"),
                        ]),
                        _c("v-select", {
                          attrs: {
                            disabled: !(
                              _vm.account.bankAccount.bank &&
                              _vm.account.bankAccount.bank.active
                            ),
                            reduce: (option) => option.value,
                            clearable: false,
                            options: [
                              { label: "SIM", value: true },
                              { label: "NÃO", value: false },
                            ],
                            placeholder: "Selecione",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          model: {
                            value: _vm.account.bankAccount.boleto,
                            callback: function ($$v) {
                              _vm.$set(_vm.account.bankAccount, "boleto", $$v)
                            },
                            expression: "account.bankAccount.boleto",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      key: "0003_",
                      staticClass: "w-full",
                      attrs: {
                        label: "Agência",
                        "data-vv-as": "Agência",
                        "data-vv-scope": "bankAccount",
                        name: "bankBranche",
                      },
                      model: {
                        value: _vm.account.bankAccount.bankBranche.code,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.bankBranche,
                            "code",
                            $$v
                          )
                        },
                        expression: "account.bankAccount.bankBranche.code",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("bankAccount.bankBranche"),
                            expression: "errors.has('bankAccount.bankBranche')",
                          },
                        ],
                        key: "0003__",
                        staticClass: "text-danger text-sm",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.errors.first("bankAccount.bankBranche"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "DV Agência" },
                      model: {
                        value: _vm.account.bankAccount.bankBranche.dv,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.bankBranche,
                            "dv",
                            $$v
                          )
                        },
                        expression: "account.bankAccount.bankBranche.dv",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      key: "0004_",
                      staticClass: "w-full",
                      attrs: {
                        label: "Conta corrente",
                        "data-vv-as": "Conta corrente",
                        "data-vv-scope": "bankAccount",
                        name: "currentAccount",
                      },
                      model: {
                        value: _vm.account.bankAccount.currentAccount.code,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.currentAccount,
                            "code",
                            $$v
                          )
                        },
                        expression: "account.bankAccount.currentAccount.code",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("bankAccount.currentAccount"),
                            expression:
                              "errors.has('bankAccount.currentAccount')",
                          },
                        ],
                        key: "0004__",
                        staticClass: "text-danger text-sm",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.errors.first("bankAccount.currentAccount"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "DV Conta corrente" },
                      model: {
                        value: _vm.account.bankAccount.currentAccount.dv,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.currentAccount,
                            "dv",
                            $$v
                          )
                        },
                        expression: "account.bankAccount.currentAccount.dv",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      !_vm.account.bankAccount.boleto && _vm.account.type === "bankAccount"
        ? _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Juros " + _vm._s(_vm.account.default_interest_type)),
                ]),
                _c(
                  "vx-input-group",
                  {
                    attrs: {
                      prependClasses:
                        "border border-solid border-grey border-r-0",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon" },
                        [
                          _c(
                            "vs-button",
                            { on: { click: _vm.toggleInterestTypeAccount } },
                            [_vm._v(_vm._s(_vm.account.default_interest_type))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.account.default_interest_type === "%"
                      ? _c("vs-input", {
                          key: "i98784811900",
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.account.default_interest,
                            callback: function ($$v) {
                              _vm.$set(_vm.account, "default_interest", $$v)
                            },
                            expression: "account.default_interest",
                          },
                        })
                      : _c("vs-input", {
                          directives: [
                            {
                              name: "currency",
                              rawName: "v-currency",
                              value: _vm.currencyConfig,
                              expression: "currencyConfig",
                            },
                          ],
                          key: "di00911848789",
                          ref: "default_interest_account",
                          staticClass: "w-full",
                          model: {
                            value: _vm.account.default_interest,
                            callback: function ($$v) {
                              _vm.$set(_vm.account, "default_interest", $$v)
                            },
                            expression: "account.default_interest",
                          },
                        }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Aplicar juros em"),
                ]),
                _c(
                  "vx-input-group",
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "number" },
                      model: {
                        value: _vm.account.default_interest_days,
                        callback: function ($$v) {
                          _vm.$set(_vm.account, "default_interest_days", $$v)
                        },
                        expression: "account.default_interest_days",
                      },
                    }),
                    _c("template", { slot: "append" }, [
                      _c("div", { staticClass: "append-text bg-primary" }, [
                        _c("span", [_vm._v("Dias")]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Multa " + _vm._s(_vm.account.default_fee_type)),
                ]),
                _c(
                  "vx-input-group",
                  {
                    attrs: {
                      prependClasses:
                        "border border-solid border-grey border-r-0",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon" },
                        [
                          _c(
                            "vs-button",
                            { on: { click: _vm.toggleFeeTypeAccount } },
                            [_vm._v(_vm._s(_vm.account.default_fee_type))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.account.default_fee_type === "%"
                      ? _c("vs-input", {
                          key: "%_fe",
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.account.default_fee,
                            callback: function ($$v) {
                              _vm.$set(_vm.account, "default_fee", $$v)
                            },
                            expression: "account.default_fee",
                          },
                        })
                      : _c("vs-input", {
                          directives: [
                            {
                              name: "currency",
                              rawName: "v-currency",
                              value: _vm.currencyConfig,
                              expression: "currencyConfig",
                            },
                          ],
                          key: "$_fe",
                          ref: "default_fee_account",
                          staticClass: "w-full",
                          model: {
                            value: _vm.account.default_fee,
                            callback: function ($$v) {
                              _vm.$set(_vm.account, "default_fee", $$v)
                            },
                            expression: "account.default_fee",
                          },
                        }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Aplicar multa em"),
                ]),
                _c(
                  "vx-input-group",
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "number" },
                      model: {
                        value: _vm.account.default_fee_days,
                        callback: function ($$v) {
                          _vm.$set(_vm.account, "default_fee_days", $$v)
                        },
                        expression: "account.default_fee_days",
                      },
                    }),
                    _c("template", { slot: "append" }, [
                      _c("div", { staticClass: "append-text bg-primary" }, [
                        _c("span", [_vm._v("Dias")]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.account.type === "cardAccount"
        ? _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    "Taxa Crédito " +
                      _vm._s(_vm.account.cardAccount.default_credit_tax_type)
                  ),
                ]),
                _c(
                  "vx-input-group",
                  {
                    attrs: {
                      prependClasses:
                        "border border-solid border-grey border-r-0",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon" },
                        [
                          _c(
                            "vs-button",
                            {
                              on: { click: _vm.toggleCardAccountCreditTaxType },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.account.cardAccount
                                    .default_credit_tax_type
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.account.cardAccount.default_credit_tax_type === "%"
                      ? _c("vs-input", {
                          key: "%_di",
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.account.cardAccount.default_credit_tax,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.account.cardAccount,
                                "default_credit_tax",
                                $$v
                              )
                            },
                            expression:
                              "account.cardAccount.default_credit_tax",
                          },
                        })
                      : _c("vs-input", {
                          directives: [
                            {
                              name: "currency",
                              rawName: "v-currency",
                              value: _vm.currencyConfig,
                              expression: "currencyConfig",
                            },
                          ],
                          key: "$_di",
                          ref: "default_credit_tax",
                          staticClass: "w-full",
                          model: {
                            value: _vm.account.cardAccount.default_credit_tax,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.account.cardAccount,
                                "default_credit_tax",
                                $$v
                              )
                            },
                            expression:
                              "account.cardAccount.default_credit_tax",
                          },
                        }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Dia Pag. Crédito"),
                ]),
                _c(
                  "vx-input-group",
                  {
                    attrs: {
                      prependClasses:
                        "border border-solid border-grey border-r-0",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon" },
                        [
                          _c(
                            "vs-button",
                            {
                              on: {
                                click:
                                  _vm.toggleCardAccountTypeDayReceiptCredit,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.account.cardAccount
                                    .type_day_receipt_credit === "FIXED"
                                    ? "Fixo"
                                    : "Após Receb."
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.account.cardAccount.type_day_receipt_credit === "FIXED"
                      ? _c("v-select", {
                          key: "%_tdc",
                          attrs: {
                            reduce: (option) => option.value,
                            clearable: false,
                            options: [
                              { label: "01", value: 1 },
                              { label: "02", value: 2 },
                              { label: "03", value: 3 },
                              { label: "04", value: 4 },
                              { label: "05", value: 5 },
                              { label: "06", value: 6 },
                              { label: "07", value: 7 },
                              { label: "08", value: 8 },
                              { label: "09", value: 9 },
                              { label: "10", value: 10 },
                              { label: "11", value: 11 },
                              { label: "12", value: 12 },
                              { label: "13", value: 13 },
                              { label: "14", value: 14 },
                              { label: "15", value: 15 },
                              { label: "16", value: 16 },
                              { label: "17", value: 17 },
                              { label: "18", value: 18 },
                              { label: "19", value: 19 },
                              { label: "20", value: 20 },
                              { label: "21", value: 21 },
                              { label: "22", value: 22 },
                              { label: "23", value: 23 },
                              { label: "24", value: 24 },
                              { label: "25", value: 25 },
                              { label: "26", value: 26 },
                              { label: "27", value: 27 },
                              { label: "28", value: 28 },
                            ],
                            placeholder: "Selecione",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n            Desculpe, nenhum resultado encontrado.\n          "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1920225863
                          ),
                          model: {
                            value: _vm.account.cardAccount.day_receipt_credit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.account.cardAccount,
                                "day_receipt_credit",
                                $$v
                              )
                            },
                            expression:
                              "account.cardAccount.day_receipt_credit",
                          },
                        })
                      : _c("vs-input", {
                          key: "$_tdc",
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.account.cardAccount.day_receipt_credit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.account.cardAccount,
                                "day_receipt_credit",
                                $$v
                              )
                            },
                            expression:
                              "account.cardAccount.day_receipt_credit",
                          },
                        }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    "Taxa Débito " +
                      _vm._s(_vm.account.cardAccount.default_debit_tax_type)
                  ),
                ]),
                _c(
                  "vx-input-group",
                  {
                    attrs: {
                      prependClasses:
                        "border border-solid border-grey border-r-0",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon" },
                        [
                          _c(
                            "vs-button",
                            {
                              on: { click: _vm.toggleCardAccountDebitTaxType },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.account.cardAccount.default_debit_tax_type
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.account.cardAccount.default_debit_tax_type === "%"
                      ? _c("vs-input", {
                          key: "%_di",
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.account.cardAccount.default_debit_tax,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.account.cardAccount,
                                "default_debit_tax",
                                $$v
                              )
                            },
                            expression: "account.cardAccount.default_debit_tax",
                          },
                        })
                      : _c("vs-input", {
                          directives: [
                            {
                              name: "currency",
                              rawName: "v-currency",
                              value: _vm.currencyConfig,
                              expression: "currencyConfig",
                            },
                          ],
                          key: "$_di",
                          ref: "default_debit_tax",
                          staticClass: "w-full",
                          model: {
                            value: _vm.account.cardAccount.default_debit_tax,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.account.cardAccount,
                                "default_debit_tax",
                                $$v
                              )
                            },
                            expression: "account.cardAccount.default_debit_tax",
                          },
                        }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Dia Pag. Débito"),
                ]),
                _c(
                  "vx-input-group",
                  {
                    attrs: {
                      prependClasses:
                        "border border-solid border-grey border-r-0",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon" },
                        [
                          _c(
                            "vs-button",
                            {
                              on: {
                                click: _vm.toggleCardAccountTypeDayReceiptDebit,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.account.cardAccount
                                    .type_day_receipt_debit === "FIXED"
                                    ? "Fixo"
                                    : "Após Receb."
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.account.cardAccount.type_day_receipt_debit === "FIXED"
                      ? _c("v-select", {
                          key: "%_tdc",
                          attrs: {
                            reduce: (option) => option.value,
                            clearable: false,
                            options: [
                              { label: "01", value: 1 },
                              { label: "02", value: 2 },
                              { label: "03", value: 3 },
                              { label: "04", value: 4 },
                              { label: "05", value: 5 },
                              { label: "06", value: 6 },
                              { label: "07", value: 7 },
                              { label: "08", value: 8 },
                              { label: "09", value: 9 },
                              { label: "10", value: 10 },
                              { label: "11", value: 11 },
                              { label: "12", value: 12 },
                              { label: "13", value: 13 },
                              { label: "14", value: 14 },
                              { label: "15", value: 15 },
                              { label: "16", value: 16 },
                              { label: "17", value: 17 },
                              { label: "18", value: 18 },
                              { label: "19", value: 19 },
                              { label: "20", value: 20 },
                              { label: "21", value: 21 },
                              { label: "22", value: 22 },
                              { label: "23", value: 23 },
                              { label: "24", value: 24 },
                              { label: "25", value: 25 },
                              { label: "26", value: 26 },
                              { label: "27", value: 27 },
                              { label: "28", value: 28 },
                            ],
                            placeholder: "Selecione",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n            Desculpe, nenhum resultado encontrado.\n          "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1920225863
                          ),
                          model: {
                            value: _vm.account.cardAccount.day_receipt_debit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.account.cardAccount,
                                "day_receipt_debit",
                                $$v
                              )
                            },
                            expression: "account.cardAccount.day_receipt_debit",
                          },
                        })
                      : _c("vs-input", {
                          key: "$_tdc",
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.account.cardAccount.day_receipt_debit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.account.cardAccount,
                                "day_receipt_debit",
                                $$v
                              )
                            },
                            expression: "account.cardAccount.day_receipt_debit",
                          },
                        }),
                  ],
                  2
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.account.bankAccount.boleto && _vm.account.type === "bankAccount"
        ? _c(
            "div",
            [
              _c(
                "vs-divider",
                { attrs: { position: "left" } },
                [
                  _c("feather-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "MenuIcon", svgClasses: "w-4 h-4" },
                  }),
                  _vm._v("Configuração de Boletos"),
                ],
                1
              ),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      key: "0005_",
                      staticClass: "w-full",
                      attrs: {
                        label: "Espécie de documento",
                        "data-vv-as": "Espécie de documento",
                        "data-vv-scope": "configBoleto",
                        name: "especie_documento",
                      },
                      model: {
                        value:
                          _vm.account.bankAccount.configBoleto
                            .especie_documento,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "especie_documento",
                            $$v
                          )
                        },
                        expression:
                          "account.bankAccount.configBoleto.especie_documento",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has(
                              "configBoleto.especie_documento"
                            ),
                            expression:
                              "errors.has('configBoleto.especie_documento')",
                          },
                        ],
                        key: "0005__",
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v("Este campo é obrigatório.")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      key: "0006_",
                      staticClass: "w-full",
                      attrs: {
                        label: "Convênio",
                        "data-vv-as": "Convênio",
                        "data-vv-scope": "configBoleto",
                        name: "convenio",
                      },
                      model: {
                        value: _vm.account.bankAccount.configBoleto.convenio,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "convenio",
                            $$v
                          )
                        },
                        expression: "account.bankAccount.configBoleto.convenio",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("configBoleto.convenio"),
                            expression: "errors.has('configBoleto.convenio')",
                          },
                        ],
                        key: "0006__",
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v("Este campo é obrigatório.")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      key: "0007_",
                      staticClass: "w-full",
                      attrs: {
                        label: "Carteira",
                        "data-vv-as": "Carteira",
                        "data-vv-scope": "configBoleto",
                        name: "carteira",
                      },
                      model: {
                        value: _vm.account.bankAccount.configBoleto.carteira,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "carteira",
                            $$v
                          )
                        },
                        expression: "account.bankAccount.configBoleto.carteira",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("configBoleto.carteira"),
                            expression: "errors.has('configBoleto.carteira')",
                          },
                        ],
                        key: "0007__",
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v("Este campo é obrigatório.")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Variação Carteira" },
                      model: {
                        value:
                          _vm.account.bankAccount.configBoleto
                            .variacao_carteira,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "variacao_carteira",
                            $$v
                          )
                        },
                        expression:
                          "account.bankAccount.configBoleto.variacao_carteira",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Impressão"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: false,
                        options: [
                          { label: "Normal", value: "normal" },
                          { label: "Carnê", value: "carne" },
                        ],
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n          Desculpe, nenhum resultado encontrado.\n        "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        716075463
                      ),
                      model: {
                        value:
                          _vm.account.bankAccount.configBoleto.printing_type,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "printing_type",
                            $$v
                          )
                        },
                        expression:
                          "account.bankAccount.configBoleto.printing_type",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Nosso nº inicial" },
                      model: {
                        value: _vm.account.bankAccount.configBoleto.next_number,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "next_number",
                            $$v
                          )
                        },
                        expression:
                          "account.bankAccount.configBoleto.next_number",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Nº inicial remessa" },
                      model: {
                        value:
                          _vm.account.bankAccount.configBoleto
                            .remessa_next_number,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "remessa_next_number",
                            $$v
                          )
                        },
                        expression:
                          "account.bankAccount.configBoleto.remessa_next_number",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Posto" },
                      model: {
                        value: _vm.account.bankAccount.configBoleto.posto,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "posto",
                            $$v
                          )
                        },
                        expression: "account.bankAccount.configBoleto.posto",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("CNAB"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: false,
                        options: [
                          { label: "240", value: 240 },
                          { label: "400", value: 400 },
                        ],
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      model: {
                        value: _vm.account.bankAccount.configBoleto.cnab,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "cnab",
                            $$v
                          )
                        },
                        expression: "account.bankAccount.configBoleto.cnab",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Byte" },
                      model: {
                        value: _vm.account.bankAccount.configBoleto.byte,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "byte",
                            $$v
                          )
                        },
                        expression: "account.bankAccount.configBoleto.byte",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        "Juros " +
                          _vm._s(
                            _vm.account.bankAccount.configBoleto
                              .default_interest_type
                          )
                      ),
                    ]),
                    _c(
                      "vx-input-group",
                      {
                        attrs: {
                          prependClasses:
                            "border border-solid border-grey border-r-0",
                        },
                      },
                      [
                        _c("template", { slot: "append" }, [
                          _c(
                            "div",
                            { staticClass: "append-text btn-addon" },
                            [
                              _c(
                                "vs-button",
                                { on: { click: _vm.toggleInterestType } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.account.bankAccount.configBoleto
                                        .default_interest_type
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm.account.bankAccount.configBoleto
                          .default_interest_type === "%"
                          ? _c("vs-input", {
                              key: "%_dit",
                              staticClass: "w-full",
                              attrs: { type: "number" },
                              model: {
                                value:
                                  _vm.account.bankAccount.configBoleto
                                    .default_interest,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.account.bankAccount.configBoleto,
                                    "default_interest",
                                    $$v
                                  )
                                },
                                expression:
                                  "account.bankAccount.configBoleto.default_interest",
                              },
                            })
                          : _c("vs-input", {
                              directives: [
                                {
                                  name: "currency",
                                  rawName: "v-currency",
                                  value: _vm.currencyConfig,
                                  expression: "currencyConfig",
                                },
                              ],
                              key: "$_dit",
                              ref: "default_interest",
                              staticClass: "w-full",
                              model: {
                                value:
                                  _vm.account.bankAccount.configBoleto
                                    .default_interest,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.account.bankAccount.configBoleto,
                                    "default_interest",
                                    $$v
                                  )
                                },
                                expression:
                                  "account.bankAccount.configBoleto.default_interest",
                              },
                            }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Aplicar juros em"),
                    ]),
                    _c(
                      "vx-input-group",
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value:
                              _vm.account.bankAccount.configBoleto
                                .default_interest_days,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.account.bankAccount.configBoleto,
                                "default_interest_days",
                                $$v
                              )
                            },
                            expression:
                              "account.bankAccount.configBoleto.default_interest_days",
                          },
                        }),
                        _c("template", { slot: "append" }, [
                          _c("div", { staticClass: "append-text bg-primary" }, [
                            _c("span", [_vm._v("Dias")]),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        "Multa " +
                          _vm._s(
                            _vm.account.bankAccount.configBoleto
                              .default_fee_type
                          )
                      ),
                    ]),
                    _c(
                      "vx-input-group",
                      {
                        attrs: {
                          prependClasses:
                            "border border-solid border-grey border-r-0",
                        },
                      },
                      [
                        _c("template", { slot: "append" }, [
                          _c(
                            "div",
                            { staticClass: "append-text btn-addon" },
                            [
                              _c(
                                "vs-button",
                                { on: { click: _vm.toggleFeeType } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.account.bankAccount.configBoleto
                                        .default_fee_type
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm.account.bankAccount.configBoleto
                          .default_fee_type === "%"
                          ? _c("vs-input", {
                              key: "%_fee",
                              staticClass: "w-full",
                              attrs: { type: "number" },
                              model: {
                                value:
                                  _vm.account.bankAccount.configBoleto
                                    .default_fee,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.account.bankAccount.configBoleto,
                                    "default_fee",
                                    $$v
                                  )
                                },
                                expression:
                                  "account.bankAccount.configBoleto.default_fee",
                              },
                            })
                          : _c("vs-input", {
                              directives: [
                                {
                                  name: "currency",
                                  rawName: "v-currency",
                                  value: _vm.currencyConfig,
                                  expression: "currencyConfig",
                                },
                              ],
                              key: "$_fee",
                              ref: "default_fee",
                              staticClass: "w-full",
                              model: {
                                value:
                                  _vm.account.bankAccount.configBoleto
                                    .default_fee,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.account.bankAccount.configBoleto,
                                    "default_fee",
                                    $$v
                                  )
                                },
                                expression:
                                  "account.bankAccount.configBoleto.default_fee",
                              },
                            }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Aplicar multa em"),
                    ]),
                    _c(
                      "vx-input-group",
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value:
                              _vm.account.bankAccount.configBoleto
                                .default_fee_days,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.account.bankAccount.configBoleto,
                                "default_fee_days",
                                $$v
                              )
                            },
                            expression:
                              "account.bankAccount.configBoleto.default_fee_days",
                          },
                        }),
                        _c("template", { slot: "append" }, [
                          _c("div", { staticClass: "append-text bg-primary" }, [
                            _c("span", [_vm._v("Dias")]),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Local de Pagamento" },
                      model: {
                        value:
                          _vm.account.bankAccount.configBoleto.payment_place,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "payment_place",
                            $$v
                          )
                        },
                        expression:
                          "account.bankAccount.configBoleto.payment_place",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Taxa Bol."),
                    ]),
                    _c("vs-input", {
                      directives: [
                        {
                          name: "currency",
                          rawName: "v-currency",
                          value: _vm.currencyConfig,
                          expression: "currencyConfig",
                        },
                      ],
                      ref: "tax_boleto",
                      staticClass: "w-full",
                      model: {
                        value: _vm.account.bankAccount.configBoleto.tax,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "tax",
                            $$v
                          )
                        },
                        expression: "account.bankAccount.configBoleto.tax",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Aplicar Taxa"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: false,
                        options: [
                          { label: "Na baixa", value: "on_payment" },
                          { label: "No Lançamento", value: "on_register" },
                        ],
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      model: {
                        value: _vm.account.bankAccount.configBoleto.tax_apply,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.account.bankAccount.configBoleto,
                            "tax_apply",
                            $$v
                          )
                        },
                        expression:
                          "account.bankAccount.configBoleto.tax_apply",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "vx-row" },
                _vm._l(
                  _vm.account.bankAccount.configBoleto
                    .demonstrative_descriptions,
                  function (demDesc, i) {
                    return _c(
                      "div",
                      {
                        key: `${i}_demonstrative_description`,
                        staticClass: "vx-col md:w-1/3 w-full mt-2",
                      },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label: `Descrição do demonstrativo linha ${i + 1}`,
                          },
                          model: {
                            value: demDesc.demonstrative_description,
                            callback: function ($$v) {
                              _vm.$set(
                                demDesc,
                                "demonstrative_description",
                                $$v
                              )
                            },
                            expression: "demDesc.demonstrative_description",
                          },
                        }),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
              _c(
                "div",
                { staticClass: "vx-row" },
                _vm._l(
                  _vm.account.bankAccount.configBoleto.instructions,
                  function (instruction, i) {
                    return _c(
                      "div",
                      {
                        key: `${i}_instruction`,
                        staticClass: "vx-col md:w-1/3 w-full mt-2",
                      },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: { label: `Instruções linha ${i + 1}` },
                          model: {
                            value: instruction.instruction,
                            callback: function ($$v) {
                              _vm.$set(instruction, "instruction", $$v)
                            },
                            expression: "instruction.instruction",
                          },
                        }),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
            ],
            1
          )
        : _vm._e(),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "ml-auto",
                  attrs: { color: "secondary" },
                  on: { click: _vm.cancel },
                },
                [_vm._v(_vm._s(_vm.cancelButtonText))]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "ml-4",
                  attrs: {
                    disabled:
                      !_vm.validateAccountForm || _vm.disableAccountSave,
                  },
                  on: { click: _vm.updateAccount },
                },
                [_vm._v(_vm._s(_vm.actionButtonText))]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }